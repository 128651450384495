import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    background: 'url(https://onwhicar.sirv.com/premas/main/LRM_EXPORT_16949881023488_20190716_173112005.jpeg) no-repeat center',
    backgroundSize: 'cover',
  },
  fontWeight900: {
    fontWeight: 900,
  },
  section: {
    background: theme.palette.secondary.main,
    opacity : '0.7',
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
      marginright: 0,
      padding: theme.spacing(9),
    
    },
  },
  textWhite: {
    color: 'white',
  },
}));

const Headplus = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Section>
        <Section className={classes.section}>
          <SectionHeader
            disableGutter
            data-aos="fade-up"
            align="left"
            titleVariant="h3"
            title={
              <span className = {clsx(classes.textWhite , classes.fontWeight900)} >OUR STORY</span>
            }
            subtitle={
              <span className={classes.textWhite}>
A strong belief several years back turned into a reality, and here we are, decades down the line, bolstering India's research & clinical community with our sturdy technology and able scientific brains.
              </span>
            }
           
          />
        </Section>
      </Section>
    </div>
  );
};

Headplus.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Headplus;
