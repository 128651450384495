import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography,  } from '@material-ui/core';
import { HeroBackground } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  backgroundColor: {
    background:theme.palette.secondary.main,
  },
  

  fontWeight900: {
    fontWeight: 900,
  },


  
  textWhite: {
    color: 'white',
  },
  title: {
    fontWeight: 'bold',
  },
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <HeroBackground className={classes.backgroundColor} backgroundImg="">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.textWhite,  classes.fontWeight900)}
            >
           Our incredible journey through the years.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.textWhite} gutterBottom>
            Premas Life Sciences (PLS) is a young, dynamic, and focused organization introducing game-changing niche technologies in Genomics, Cell Biology, and Biopharma to boost innovative research and diagnostics in India. We are also the knowledge partners to several reputed research institutes and hospitals, enabling them to set up core genomics facilities with complete support at all fronts.
            </Typography>
            <Typography variant="h6" className={classes.textWhite}>
            The biggest motivation behind the inception of PLS was to set up an organization that could contribute significantly to the life science research landscape in India and has the convergence of a committed and highly skilled workforce to catalyze this process.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
       
          </Grid>
        </Grid>
      </HeroBackground>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
