/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 * 
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import Ourstory from 'views/Ourstory';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const OurstoryPage = () => {
    return (
        <WithLayout
            component={Ourstory}
            layout={Main}
        />
    )
};

export default OurstoryPage;
