import React from 'react';
import PropTypes from 'prop-types';
import { Grid, colors, makeStyles } from '@material-ui/core';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { CardBase, DescriptionListIcon } from 'components/organisms';

const useStyles = makeStyles(() => ({
  fontWeight900: {
    fontWeight: 900,
  },
}));

const data = [{
  icon: 'fas fa-cubes',
  color: colors.blueGrey,
  title: '2005  ',
  subtitle: 'Inception of Premas Biotech ,A CRO with expertise in recombinant protein development',
}, {
  icon: 'fas fa-star-of-life',
  color: colors.blueGrey,
  title: '2011 ',
  subtitle: ' Inception of Premas Life Sciences with first Legit Office in Okhla Phase I',
}, {
  icon: 'fab fa-instalod',
  color: colors.blueGrey,
  title: '2014',
  subtitle: 'First 10 Illumina installations in the country',
}, {
  icon: 'far fa-building',
  color: colors.blueGrey,
  title: '2017',
  subtitle: 'Established our modern “DNA wall” office',
}, {
  icon: 'fas fa-medal',
  color: colors.blueGrey,
  title: '2019',
  subtitle: 'Illumina Elite Channel Partners Award',
}, {
  icon: 'fas fa-photo-video',
  color: colors.blueGrey,
  title: '2020',
  subtitle: 'Published by Fortune India',
}];

const Timeline= ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
      label = 'timeline'
        title="Our incredible journey through the years"
        fadeUp
        titleProps={{
          variant: 'h4',
          color: 'textPrimary',
          className: classes.fontWeight900,
        }}
      />
      <Grid container spacing={2}>
        {data.map((adv, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={6}
            md={4}
            data-aos="fade-up"
          >
            <CardBase
              liftUp
              variant="outlined"
              style={{ borderTop: `5px solid ${adv.color[500]}` }}
            >
              <DescriptionListIcon
                icon={
                  <IconAlternate
                    fontIconClass={adv.icon}
                    color={adv.color}
                    shape="circle"
                    size="small"
                  />
                }
                title={adv.title}
                subtitle={adv.subtitle}
                align="left"
              />
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Timeline.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Timeline;
