import React from 'react';
import emailjs from 'emailjs-com';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import {  Divider } from '@material-ui/core';
import { Timeline } from './components';
import { Section, SectionAlternate } from 'components/organisms';
import Ceo from './components/Ceo'
import Managingd from './components/Managingd'
import Vpsupport from './components/Vpsupport'
import Gpm from './components/Gpm';


import Headplus from './components/Headplus'
import {
    TextField,
    Grid,
    Typography,
    useMediaQuery,
 
    Button,
   
  } from '@material-ui/core';




// import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';






import {

    Advantages,



    Hero,
    // PromoNumbers,
    Reviews



} from './components';

import {
    // promoNumbers,
    advantages,
    reviews,




    // promo,

} from './data';

import Sweepplay from './components/Sweepplay'
import {play} from './data'
import { SectionHeader } from 'components/molecules';









// const weeat = [
//     {
//         cover: {
//             src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/3_0gZyJVPVP.jpg',
//             srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/3_0gZyJVPVP.jpg',
//         },


//     },
//     {
//         cover: {
//             src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/4_WThnJJ-lR.jpg',
//             srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/4_WThnJJ-lR.jpg',
//         },


//     },
//     {
//         cover: {
//             src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/5_Cdqv9HtTK.jpg',
//             srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/5_Cdqv9HtTK.jpg',
//         },


//     },
//     {
//         cover: {
//             src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_rubNSrtF3.JPG',
//             srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_rubNSrtF3.JPG',
//         },


//     },
//     {
//         cover: {
//             src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/3_RdjZwE4ci.jpeg',
//             srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/3_RdjZwE4ci.jpeg',
//         },


//     },


// ];

















const useStyles = makeStyles(theme => ({
  
  fontWeight900: {
    fontWeight: 900,
  },
    pagePaddingTop: {
        paddingTop: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
          paddingTop: theme.spacing(5),
        },
      },
      sectionNoPaddingTop: {
        paddingTop: 0,
      },
      promoSection: {
        background: theme.palette.alternate.main,
        padding: theme.spacing(6, 2),
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(12, 2),
        },
      },
    }));

const Ourstory = () => {


  function sendEmail(e){
    e.preventDefault();


    emailjs.sendForm('service_wbp4tq6', 'template_mv1ypf9', e.target, 'user_cWibQO28lgt1qInNbXRs5').then(res =>{
        console.log(res);
    }).catch(err=> console.log(err));
    e.target.reset();
}


 

    // const [user , setUser] = useState({
    //     name:"",
    //     email:"",
    //     organization:"",
    //     message:"",
    //   });
    
    
    //   let name , value;
    //   const getUserData = (event) => {
    //     name= event.target.name;
    //     value=event.target.value;
    
    //     setUser({...user , [name]:value });
    
    //   };
    
    //   const postData = async (e)=>{
    //     e.preventDefault();
    //     const {name,email, message} = user;
    
    //     if ( name && email && message ){
    
    
    //       const res = await fetch (" https://premas-life-sciences-default-rtdb.firebaseio.com/connect.json" , 
          
    //       {
      
    //         method: "POST",
    //         headers:{
    //           "Content-Type":"application/json",
    //         },
      
    //         body:JSON.stringify({
    //         name,
    //         email,
           
    //         message,
    //         }),
      
      
    //       });
      
    //       if (res){
    //         setUser({
      
    //           name:"",
    //           email:"",
    //       organization:"",
    //       message:"",
    //         });
      
    //         alert("WE will get back to you soon !")
    //       }
    
    
    //     } else{
    
    //       alert("please fill alll the data")
          
    //     }
    
    
    
    
    //   };
    
    
    const classes = useStyles();
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
      });
    

    return (
        <div>
            <Headplus />
            <Section >

<Timeline/>

</Section>

            <Hero />
            {/* <SectionAlternate className={classes.sectionDescription}>
                <Description />
            </SectionAlternate> */}

 









            {/* <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#000000', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #000000' }}
                    date="2011- "
                    iconStyle={{ background: '#000000', color: '#fff' }}
                // icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">2011</h3>
                    <h4 className="vertical-timeline-element-subtitle">India</h4>
                    <p>
                        PREMAS LIFESCIENCES FOUNDED
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#000000', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #000000' }}
                    date="2011- 2013 "
                    iconStyle={{ background: '#000000', color: '#fff' }}
                // icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">2013</h3>
                    <h4 className="vertical-timeline-element-subtitle">funding</h4>
                    <p>
                        Team , content goes here !
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#000000', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #000000' }}
                    date="2011- "
                    iconStyle={{ background: '#000000', color: '#fff' }}
                // icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">2018</h3>
                    <h4 className="vertical-timeline-element-subtitle">Best Enterprsie product</h4>
                    <p>
                        content goes here
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#000000', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #000000' }}

                    iconStyle={{ background: '#000000', color: '#fff' }}
                    date="2011- 2013 "
                // icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">2021</h3>
                    <h4 className="vertical-timeline-element-subtitle">Covid19 solution partner</h4>
                    <p>

                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    contentStyle={{ background: '#000000', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #000000' }}

                    className="vertical-timeline-element--education"
                    date="April 2013"
                    iconStyle={{ background: '#000000', color: '#fff' }}
                // icon={<SchoolIcon />}
                >
                    <h3 className="vertical-timeline-element-title">We will redeisgn visual after content</h3>
                    <h4 className="vertical-timeline-element-subtitle">use subtitle here</h4>
                    <p>
                        Team premas , Icon can be placed inside a circle timeline
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    contentStyle={{ background: '#000000', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #000000' }}
                    className="vertical-timeline-element--education"
                    date="November 2012"
                    iconStyle={{ background: '#000000', color: '#fff' }}
                // icon={<SchoolIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Agile Development </h3>
                    <h4 className="vertical-timeline-element-subtitle">we have applied the basic css to the  timeline</h4>
                    <p>
                        Team , if you have any visual need regarding placement let us know.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    contentStyle={{ background: '#000000', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #000000' }}

                    className="vertical-timeline-element--education"
                    date="April 2013"
                    iconStyle={{ background: '#000000', color: '#fff' }}
                // icon={<SchoolIcon />}
                >
                    <h3 className="vertical-timeline-element-title">We will redeisgn visual after content</h3>
                    <h4 className="vertical-timeline-element-subtitle">use subtitle here</h4>
                    <p>
                        Team, Timeline contains three major content alignment , headline , sub and content.
                    </p>
                </VerticalTimelineElement>


            </VerticalTimeline> */}

            <Divider />

            <Section>
                <Ceo />
            </Section>
            <SectionAlternate>
                <Managingd />
            </SectionAlternate>
            <Section>
                <Vpsupport />
            </Section>
            <SectionAlternate>
                < Gpm />
            </SectionAlternate>


            <Section>
                <Advantages data={advantages} />
            </Section>

            <Divider />
            <section className = {classes.promoSection}>
                <Sweepplay data = {play} />
     
               
                </section>

              
               
            
              
               
               
     


            <Section>

            <SectionHeader
                label = 'testimonials'
                    title={
                        <span>
                            Our {' '}
                            <Typography color="secondary" variant="h3" component="span" className= {classes.fontWeight900}>Employees</Typography>
                            <Typography variant="inherit" component="span"> love  </Typography>
                            <Typography color="secondary" variant="h3" component="span" className={classes.fontWeight900} >us</Typography>
                        </span>
                    }
                    subtitle=" And boast highly of us. We are incredibly grateful for our customer's belief in our capabilities.
                    ."
                    align="center"
                    fadeUp
                />

                <Reviews data={reviews} />

            </Section>
            <div >
               
                
                <Section narrow>
                <SectionHeader
                align = 'center'
                title={
                  <span>
                   
                      <Typography color="secondary" variant="h3" component="span" className= {classes.fontWeight900}>Collaborate</Typography>
                      <Typography variant="inherit" component="span"> with </Typography>
                      <Typography variant="inherit" component="span"> us </Typography>
                  </span>
              }
                subtitle="Think our vision matches with yours? Let’s talk. We are always open for potential partnerships."
                 />
                <div className={classes.form}>
                <form encType = 'multipart/form-data' method = 'post' onSubmit = {sendEmail}>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} data-aos="fade-up">
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
               Name
            </Typography>
            <TextField
              placeholder="Your full name"
           required
              variant="outlined"
              size="medium"
              name="name"
              fullWidth
              type="text"
            />
          </Grid>
          <Grid item xs={12} data-aos="fade-up">
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              E-mail
            </Typography>
            <TextField
              placeholder="Your e-mail address"
required
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              type="email"
            />
          </Grid>
        
     
          <Grid item xs={12} data-aos="fade-up">
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Message
            </Typography>
            <TextField
              placeholder="Your question about our services"
  
              variant="outlined"
              name="message"
              fullWidth
              multiline
              rows={4}
              required
            />
          </Grid>
          <Grid item container justify="center" xs={12}>
            <Button
              variant="contained"
      
              type="submit"
              color="primary"
              size="large"
            >
              Apply now
            </Button>
          </Grid>
        </Grid>
        </form>
      </div>
                    {/* <Contact /> */}
                </Section>
            </div>
        </div>
    );
};

export default Ourstory;
