
export const promo = [
    {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_hqTPplVJR.jpeg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_hqTPplVJR.jpeg',
        alt: '...',
    },
    {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG-20191023-WA0060_hMSkc9Qzhw.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG-20191023-WA0060_hMSkc9Qzhw.jpg',
        alt: '...',
    },
    {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/1_LlgB34Vfs.jpeg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/1_LlgB34Vfs.jpeg',
        alt: '...',
    },
    {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/4_ideOaxOFX.jpeg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/4_ideOaxOFX.jpeg',
        alt: '...',
    },
    {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/3_RdjZwE4ci.jpeg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/3_RdjZwE4ci.jpeg',
        alt: '...',
    },
    {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/3_0gZyJVPVP.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/3_0gZyJVPVP.jpg',
        alt: '...',
    },
    {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/4_WThnJJ-lR.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/4_WThnJJ-lR.jpg',
        alt: '...',
    }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/5_Cdqv9HtTK.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/5_Cdqv9HtTK.jpg',
        alt: '...',
    }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_rubNSrtF3.JPG',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_rubNSrtF3.JPG',
        alt: '...',
    }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_P5A0nJybe.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_P5A0nJybe.jpg',
        alt: '...',
    }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG_20190802_154418_999_d5AtY6w56.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG_20190802_154418_999_d5AtY6w56.jpg',
        alt: '...',
    }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG-20191021-WA0141_1yri6cvC_.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG-20191021-WA0141_1yri6cvC_.jpg',
        alt: '...',
    }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG-20191021-WA0058_6m6T9sAVJ.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG-20191021-WA0058_6m6T9sAVJ.jpg',
        alt: '...',
    }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG_5730_5sYC_SRqmW.JPG',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG_5730_5sYC_SRqmW.JPG',
        alt: '...',
    }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_1__4w6d6Lt67.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_1__4w6d6Lt67.jpg',
        alt: '...',
    }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/4_1__x3jr2GQkr.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/4_1__x3jr2GQkr.jpg',
        alt: '...',
    },
    {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/5_1__yiiFgh1PH.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/5_1__yiiFgh1PH.jpg',
        alt: '...',
    },
    {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/1_rPoE_3rGIR.JPG',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/1_rPoE_3rGIR.JPG',
        alt: '...',
    },

];








export const promoNumbers = [
    {
        title: 'FOUNDED',
        number: '2011',
    },
    {
        title: 'Best Enterprise product',
        number: '2018',
    },
    {
        title: 'covid partner',
        number: '2021',
    },
    {
        title: 'Brands',
        number: '15+',
    },
    {
        title: "Research",
        number: '260+',
    },
    {
        title: 'Installations',
        number: '200+',
    },
    {
        title: 'Employees',
        number: '60+',
    },
];

export const advantages = [
    {
        icon: 'fas fa-rocket',
        title: 'Brand awareness',
        subtitle:
            'The foundation of Premas was laid down when ‘start-up culture’ was not a buzz word. our passion coupled with the technical prowess helped us establish faith in our customers ultimately conquering these hurdles down the line.',
    },
    {
        icon: 'fas fa-brain',
        title: 'Thought leadership',
        subtitle:
            ' Premas Life Sciences partnered with the American science research company Genscript to distribute a rapid SARS-CoV-2 surrogate virus neutralisation test (sVNT) kit called cPass in India. we are reinventing health culture in India.'
    },
    {
        icon: 'far fa-comments',
        title: 'Networking',
        subtitle:
            ' we have managed to keep intact throughout our growing years. Our mantra has always been a customer-centric approach and it has paid off well when we witness customers advocating us as to others in the market.',
    },
    {
        icon: 'fas fa-bullhorn',
        title: 'Vision',
        subtitle:
            " To be innovative is to be unique in our own way – that’s what makes one relevant. To lead the race, one has to develop a change in perspective and be able to “think outside the box” that differentiates them from the crowd. It puts them on a pedestal.",
    },
];

export const partners = [
    {
        name: 'Airbnb',
        logo: 'https://assets.maccarianagency.com/the-front/logos/airbnb.svg',
    },
    {
        name: 'Coinbase',
        logo: 'https://assets.maccarianagency.com/the-front/logos/coinbase.svg',
    },
    {
        name: 'Dribbble',
        logo: 'https://assets.maccarianagency.com/the-front/logos/dribbble.svg',
    },
    {
        name: 'Instagram',
        logo: 'https://assets.maccarianagency.com/the-front/logos/instagram.svg',
    },
    {
        name: 'Netflix',
        logo: 'https://assets.maccarianagency.com/the-front/logos/netflix.svg',
    },
    {
        name: 'Pinterest',
        logo: 'https://assets.maccarianagency.com/the-front/logos/pinterest.svg',
    },
];

export const webinars = [
    {
        title: 'Manage Service Providers',
        description:
            'Avijit was the Group Director, Eastern Hemisphere Operations for Gillette-Duracell and a management consultant with AT Kearney before becoming an entrepreneur. He is associated with several enterprises across the globe and has significant management experience of more than 30 years now',
        authorPhoto: {
            src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
            srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
        },
        authorName: 'Veronica Adams',
    },
    {
        title: 'Value Added Resellers',
        description:
            'Add Social WiFi Marketing to your software bundles and suite of services.',
        authorPhoto: {
            src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
            srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
        },
        authorName: 'Akachi Luccini',
    },
    {
        title: 'Digital Services Agencies',
        description:
            'Help local business owners generate leads and build customer loyalty.',
        authorPhoto: {
            src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
            srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
        },
        authorName: 'Jack Smith',
    },
    {
        title: 'Internet Service Providers',
        description:
            'Upsell Social WiFi as a Service to business Internet subscribers.',
        authorPhoto: {
            src: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson.jpg',
            srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson@2x.jpg 2x',
        },
        authorName: 'Kate Segelson',
    },
    {
        title: 'SaaS Aplications',
        description:
            'A lightweight Social WiFi API solution integrate deep into existing platforms.',
        authorPhoto: {
            src: 'https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg',
            srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/alex-johnson@2x.jpg 2x',
        },
        authorName: 'Alex Johnson',
    },
    {
        title: 'Advertising Network',
        description:
            'Generate affiliate commissions by referring new reseller partners to us.',
        authorPhoto: {
            src: 'https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan.jpg',
            srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan@2x.jpg 2x',
        },
        authorName: 'Valeria Kogan',
    },
];

export const reviews = [
    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/people/Dhaval%20Tabib.jpg',
            srcSet: 'https://onwhicar.sirv.com/premas/main/people/Dhaval%20Tabib.jpg',
        },
        authorName: 'Dhaval Tabib',
        authorOccupation: 'Regional Sales Manager',
        feedback:
            'Thinking of my experience in PLS, the one word that strikes my mind at once is “Marvelous”. There’s not a day when I have not loved my job. Learning something new each day with my juniors, seniors and mentors is a cherry on cake.',
    },
    {
        authorPhoto: {
            src: 'https://onwhicar.sirv.com/premas/main/people/Divya%20Jyoti%20Singh.jpg',
            srcSet: 'https://onwhicar.sirv.com/premas/main/people/Divya%20Jyoti%20Singh.jpg',
        },
        authorName: 'Divyajyoti Singh',
        authorOccupation: 'Assistant Manager',
        feedback:
            'I couldn’t have imagined a better place to bloom than PLS because of the kind of exposure one gets, it is the key to personal growth development. As tough as it can get, I still enjoy each aspect of my journey in here ',
    },
    {
        authorPhoto: {
            src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/Satinder_Partap_k5WO-0W6h.jfif?updatedAt=1626950932504',
            srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/Satinder_Partap_k5WO-0W6h.jfif?updatedAt=1626950932504',
        },
        authorName: 'Satinder partap',
        authorOccupation: 'Assistant Manager - Customer Support',
        feedback:
            'The time spent so far at PLS is nothing short of a holistic learning experience. My team has been super supportive and helped me evolve as a professional. I look forward to continuing my stint with full vigor. ',
    },
];

export const play = [
    {
        image: {
            src: 'https://onwhicar.sirv.com/premas/main/people/2.jpeg" width="2976',
            srcSet: 'https://onwhicar.sirv.com/premas/main/people/2.jpeg" width="2976',
        },
        title: 'gallery',
        cols: 1,
    },
    {
        image: {
            src: 'https://onwhicar.sirv.com/premas/main/people/IMG-20191023-WA0060.jpg',
            srcSet: 'https://onwhicar.sirv.com/premas/main/people/IMG-20191023-WA0060.jpg',
        },
        title: 'gallery',
        cols: 3,
    },
    {
        image: {
            src: 'https://onwhicar.sirv.com/premas/main/people/1.jpeg',
            srcSet: 'https://onwhicar.sirv.com/premas/main/people/1.jpeg',
        },
        title: 'gallery',
        cols: 2,
    },
    {
        image: {
            src: 'https://onwhicar.sirv.com/premas/main/people/2.jpg',
            srcSet: 'https://onwhicar.sirv.com/premas/main/people/2.jpg',
        },
        title: 'gallery',
        cols: 1,
    },
    {
        image: {
            src: 'https://onwhicar.sirv.com/premas/main/people/3.jpg',
            srcSet: 'https://onwhicar.sirv.com/premas/main/people/3.jpg',
        },
        title: 'gallery',
        cols: 1,
    },
    {
        image: {
            src: 'https://onwhicar.sirv.com/premas/main/people/4.jpg',
            srcSet: 'https://onwhicar.sirv.com/premas/main/people/4.jpg',
        },
        title: 'gallery',
        cols: 1,
    },
    {
        image: {
            src: 'https://onwhicar.sirv.com/premas/main/people/IMG-20191021-WA0058.jpg',
            srcSet: 'https://onwhicar.sirv.com/premas/main/people/IMG-20191021-WA0058.jpg',
        },
        title: 'gallery',
        cols: 2,
    },
    {
        image: {
            src: 'https://onwhicar.sirv.com/premas/main/people/IMG-20191021-WA0141.jpg',
            srcSet: 'https://onwhicar.sirv.com/premas/main/people/IMG-20191021-WA0141.jpg',
        },
        title: 'gallery',
        cols: 1,
    },
];

export const teasers = [
    {
        image: {
            src: 'Avijit was the Group Director, Eastern Hemisphere Operations for Gillette-Duracell and a management consultant with AT Kearney before becoming an entrepreneur. He is associated with several enterprises across the globe and has significant management experience of more than 30 years now',
            srcSet: 'https://ik.imagekit.io/j6phbjneuop/Untitled_QX0VBv0bM.png',
        },
        title: 'The New Era of Tech Companies',
        subtitle:
            "Drawing thousands of the world's technology leaders together to learn and do business",
        description:
            'Meh synth Schlitz, tempor duis single-origin coffee ea next level ethnic fingerstache fanny pack nostrud. Photo booth anim 8-bit hella, PBR 3 wolf moon beard Helvetica.',
    },
    {
        image: {
            src: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery2.jpg',
            srcSet: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery2@2x.jpg 2x',
        },
        title: 'We have top executive & start up here',
        subtitle:
            "Drawing thousands of the world's technology leaders together to learn and do business",
        description:
            'Meh synth Schlitz, tempor duis single-origin coffee ea next level ethnic fingerstache fanny pack nostrud. Photo booth anim 8-bit hella, PBR 3 wolf moon beard Helvetica.',
    },
];


export const gallery = [
    {
      src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_hqTPplVJR.jpeg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_hqTPplVJR.jpeg',
      alt: '...',
    },
    {
      src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG-20191023-WA0060_hMSkc9Qzhw.jpg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG-20191023-WA0060_hMSkc9Qzhw.jpg',
      alt: '...',
    },
    {
      src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/1_LlgB34Vfs.jpeg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/1_LlgB34Vfs.jpeg',
      alt: '...',
    },
    {
      src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/4_ideOaxOFX.jpeg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/4_ideOaxOFX.jpeg',
      alt: '...',
    },
    {
      src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/3_RdjZwE4ci.jpeg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/3_RdjZwE4ci.jpeg',
      alt: '...',
    },
  
    {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/3_0gZyJVPVP.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/3_0gZyJVPVP.jpg',
        alt: '...',
      },
      {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/4_WThnJJ-lR.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/4_WThnJJ-lR.jpg',
        alt: '...',
      }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/5_Cdqv9HtTK.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/5_Cdqv9HtTK.jpg',
        alt: '...',
      }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_rubNSrtF3.JPG',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_rubNSrtF3.JPG',
        alt: '...',
      }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_P5A0nJybe.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_P5A0nJybe.jpg',
        alt: '...',
      }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG_20190802_154418_999_d5AtY6w56.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG_20190802_154418_999_d5AtY6w56.jpg',
        alt: '...',
      }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG-20191021-WA0141_1yri6cvC_.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG-20191021-WA0141_1yri6cvC_.jpg',
        alt: '...',
      }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG-20191021-WA0058_6m6T9sAVJ.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG-20191021-WA0058_6m6T9sAVJ.jpg',
        alt: '...',
      }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG_5730_5sYC_SRqmW.JPG',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/IMG_5730_5sYC_SRqmW.JPG',
        alt: '...',
      }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_1__4w6d6Lt67.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/2_1__4w6d6Lt67.jpg',
        alt: '...',
      }, {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/4_1__x3jr2GQkr.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/4_1__x3jr2GQkr.jpg',
        alt: '...',
      },
      {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/5_1__yiiFgh1PH.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/5_1__yiiFgh1PH.jpg',
        alt: '...',
      },
      {
        src: 'https://ik.imagekit.io/j6phbjneuop/premasweb/1_rPoE_3rGIR.JPG',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/premasweb/1_rPoE_3rGIR.JPG',
        alt: '...',
      },
    
  ];